@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  margin: 0px;
  overflow: hidden;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text-normal {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  flex-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  flex-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.details-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #3c3c3c;
}

.details-datetime {
  height: 22px;
  background: #6ad26f;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
}

.device-enabled {
  background: #ffffff;
  border: 2px solid #6ad26f;
  box-sizing: border-box;
  box-shadow: 5px 5px 10px rgba(13, 39, 80, 0.298039), -2px -2px 5px #cbd4e2;
  border-radius: 10px;

  height: 100%;
}

.device-disabled {
  background: #ffffff;
  border: 2px solid #ff0000;
  box-sizing: border-box;
  box-shadow: 5px 5px 10px rgba(13, 39, 80, 0.298039), -2px -2px 5px #cbd4e2;
  border-radius: 10px;

  height: 100%;
}